import "./App.css";
import React, { useState, useEffect } from "react";

function App() {
    const prizeMoney = [
        3240000, 1944000, 1224000, 864000, 720000, 648000, 603000, 558000,
        522000, 486000, 450000, 414000, 378000, 342000, 324000, 306000, 288000,
        270000, 252000, 234000, 216000, 201600, 187200, 172800, 158400, 144000,
        138600, 133200, 127800, 122400, 117000, 111600, 106200, 101700, 97200,
        92700, 88200, 84600, 81000, 77400, 73800, 70200, 66600, 63000, 59400,
        55800, 52200, 49320, 46800, 45360, 45360, 45360, 45360, 45360, 45360,
        45360, 45360, 45360, 45360, 45360, 45360, 45360, 45360, 45360, 45360,
        45360, 45360, 45360, 45360, 45360, 45360, 45360,
    ];

    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    const picks = [
        {
            name: "Murph",
            picks: [
                "Scottie Scheffler",
                "Justin Thomas",
                "Tommy Fleetwood",
                "Adam Scott",
                "Sergio Garcia",
            ],
            summary: [],
            totalMoney: 0,
        },
        {
            name: "Braddy",
            picks: [
                "Scottie Scheffler",
                "Joaquín Niemann",
                "Corey Conners",
                "Sahith Theegala",
                "Tommy Fleetwood",
            ],
            summary: [],
            totalMoney: 0,
        },
        {
            name: "Rory",
            picks: [
                "Scottie Scheffler",
                "Cameron Smith",
                "Sahith Theegala",
                "Sungjae Im",
                "Keegan Bradley",
            ],
            summary: [],
            totalMoney: 0,
        },
        {
            name: "Frank",
            picks: [
                "Rory McIlroy",
                "Joaquín Niemann",
                "Tiger Woods",
                "Adam Scott",
                "Tyrrell Hatton",
            ],
            summary: [],
            totalMoney: 0,
        },
        {
            name: "Gar",
            picks: [
                "Scottie Scheffler",
                "Cameron Smith",
                "Rickie Fowler",
                "Phil Mickelson",
                "Tommy Fleetwood",
            ],
            summary: [],
            totalMoney: 0,
        },
        {
            name: "Meany",
            picks: [
                "Scottie Scheffler",
                "Bryson DeChambeau",
                "Sahith Theegala",
                "Sepp Straka",
                "Si Woo Kim",
            ],
            summary: [],
            totalMoney: 0,
        },
        {
            name: "Davey C",
            picks: [
                "Scottie Scheffler",
                "Ludvig Åberg",
                "Corey Conners",
                "Adam Scott",
                "Phil Mickelson",
            ],
            summary: [],
            totalMoney: 0,
        },
        {
            name: "Vince",
            picks: [
                "Scottie Scheffler",
                "Joaquín Niemann",
                "Akshay Bhatia",
                "Sahith Theegala",
                "Sergio Garcia",
            ],
            summary: [],
            totalMoney: 0,
        },
        {
            name: "Timmy",
            picks: [
                "Will Zalatoris",
                "Cameron Young",
                "Min Woo Lee",
                "Adam Scott",
                "Russell Henley",
            ],
            summary: [],
            totalMoney: 0,
        },
        {
            name: "Q",
            picks: [
                "Jon Rahm",
                "Justin Thomas",
                "Min Woo Lee",
                "Rickie Fowler",
                "Adam Scott",
            ],
            summary: [],
            totalMoney: 0,
        },
    ];

    async function getLeaders() {
        // Default options are marked with *
        const response = await fetch(
            "https://site.web.api.espn.com/apis/site/v2/sports/golf/leaderboard",
            {
                headers: {
                    accept: "*/*",
                    "accept-language": "en-GB,en-US;q=0.9,en;q=0.8",
                    "sec-fetch-dest": "empty",
                    "sec-fetch-mode": "cors",
                    "sec-fetch-site": "same-site",
                },
                referrer: "https://www.espn.com/",
                referrerPolicy: "strict-origin-when-cross-origin",
                body: null,
                method: "GET",
                mode: "cors",
                credentials: "omit",
            }
        );
        return response.json(); // parses JSON response into native JavaScript objects
    }

    const [moneyBreakdown, setMoneyBreakdown] = useState({});
    const [rankings, setRankings] = useState(picks);

    useEffect(() => {
        getLeaders().then((response) => {
            const allPlayers = response.events[0].competitions[0].competitors;
            const playersNotCut = allPlayers.filter(
                (player) => player.status.type.name !== "STATUS_CUT"
            );
            const leaderBoard = playersNotCut.sort((a, b) => {
                if (
                    parseInt(a.status.position.id) <
                    parseInt(b.status.position.id)
                ) {
                    return -1;
                }
                if (
                    parseInt(a.status.position.id) >
                    parseInt(b.status.position.id)
                ) {
                    return 1;
                }
                return 0;
            });

            const moneyBreakdownRework = leaderBoard.reduce(
                (summary, player, currentIndex) => {
                    summary[player.status.position.id] = {
                        amountTotal:
                            (summary[player.status.position.id]?.amountTotal ||
                                0) + (prizeMoney[currentIndex] || 0),
                        sharedBetween:
                            (summary[player.status.position.id]
                                ?.sharedBetween || 0) + 1,
                        score: player.linescores.reduce((total, scores) => {
                            if (!scores.displayValue) return total + 0;
                            let actualValue =
                                scores.displayValue === "E" ||
                                scores.displayValue === "-"
                                    ? 0
                                    : parseInt(scores.displayValue);
                            return total + actualValue;
                        }, 0),
                        players:
                            summary[player.status.position.id]?.players || [],
                        index: currentIndex,
                    };

                    summary[player.status.position.id].amountEach =
                        summary[player.status.position.id].amountTotal /
                        summary[player.status.position.id].sharedBetween;

                    summary[player.status.position.id].players.push(
                        player.athlete
                    );

                    return summary;
                },
                {}
            );

            Object.keys(moneyBreakdownRework).map((key) => {
                const place = moneyBreakdownRework[key];
                place.players.forEach((player) => {
                    picks.forEach((user) => {
                        if (user.picks.indexOf(player.displayName) !== -1) {
                            user.totalMoney =
                                user.totalMoney + place.amountEach;
                            user.summary.push({
                                amount: place.amountEach,
                                name: player.displayName,
                                avatarURL: player.headshot?.href,
                            });
                        }
                    });
                });
            });
            picks.sort((a, b) => {
                if (a.totalMoney > b.totalMoney) {
                    return -1;
                }
                if (a.totalMoney < b.totalMoney) {
                    return 1;
                }
                return 0;
            });

            setMoneyBreakdown(moneyBreakdownRework);
            setRankings(picks);
        });
    }, []);

    const lookUpPlayerName = (person, pick) => {
        let playerName = person.summary?.find(({ name }) => name === pick).name;

        if (person.name === "Timmy") {
            switch (pick) {
                case "Adam Scott":
                    playerName = "Scott";
                    break;
                case "Will Zalatoris":
                    playerName = "Will Clitoris";
                    break;
                case "Russell Henley":
                    playerName = "Hendy";
                    break;
                case "Min Woo Lee":
                    playerName = "Woo";
                    break;
                default:
                //do nothing
            }
        }
        return playerName;
    };

    return (
        <div className="app-main">
            <div className="rankings">
                {rankings.map((person) => {
                    return (
                        <div>
                            <div className="rankings-header">
                                <div style={{ width: 100, padding: 10 }}>
                                    {person.name}
                                </div>
                                <div>
                                    {formatter.format(
                                        Math.floor(person.totalMoney)
                                    )}
                                </div>
                            </div>
                            <div className="picks">
                                {person.picks.map((pick) => {
                                    return (
                                        <div>
                                            {person.summary?.find(
                                                ({ name }) => name === pick
                                            ) ? (
                                                <div
                                                    style={{
                                                        paddingBottom: 10,
                                                    }}
                                                >
                                                    <div className="individual-picks">
                                                        <img
                                                            className="player-img-rankings"
                                                            src={
                                                                person.summary?.find(
                                                                    ({
                                                                        name,
                                                                    }) =>
                                                                        name ===
                                                                        pick
                                                                ).avatarURL
                                                            }
                                                        />
                                                        <div
                                                            style={{
                                                                paddingLeft: 5,
                                                            }}
                                                        >
                                                            {lookUpPlayerName(
                                                                person,
                                                                pick
                                                            )}
                                                            <div>
                                                                {formatter.format(
                                                                    person.summary?.find(
                                                                        ({
                                                                            name,
                                                                        }) =>
                                                                            name ===
                                                                            pick
                                                                    ).amount
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="cut">
                                                    {pick}
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="player-breakdown">
                {Object.keys(moneyBreakdown).map(function (keyName) {
                    return (
                        <div>
                            <div className="player-breakdown-header">
                                <div className="player-breakdown-score">
                                    {keyName}
                                </div>
                                <div
                                    style={{
                                        width: 40,
                                        backgroundColor:
                                            parseInt(
                                                moneyBreakdown[keyName].score
                                            ) < 0
                                                ? "red"
                                                : "gray",
                                        textAlign: "center",
                                    }}
                                >
                                    {moneyBreakdown[keyName].score > 0
                                        ? "+"
                                        : ""}
                                    {moneyBreakdown[keyName].score === 0
                                        ? "E"
                                        : moneyBreakdown[keyName].score}
                                </div>
                                <div style={{ width: 200, textAlign: "right" }}>
                                    ({moneyBreakdown[keyName].sharedBetween}){" "}
                                    {formatter.format(
                                        moneyBreakdown[keyName].amountEach
                                    )}
                                </div>
                            </div>
                            <div style={{ paddingBottom: 20 }}>
                                {moneyBreakdown[keyName].players?.map(
                                    (player) => {
                                        return (
                                            <div>
                                                <img
                                                    className="player-img"
                                                    src={player.headshot?.href}
                                                />
                                                {player.displayName}
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="prize-money">
                <div className="prize-money-header">PRIZE MONEY</div>
                <div className="prize-money-header-total">
                    ($18m Total Prize Money)
                </div>
                {prizeMoney.map((amount, index) => {
                    return (
                        <div style={{ width: 300 }}>
                            {index + 1} - {formatter.format(amount)}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default App;
